import React, { useState, useEffect } from 'react';
import './App.css';
import Modal from 'react-modal';
// import Navbar from './Navbar'; // Import the Navbar component
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Form, Col, Row, Alert} from 'react-bootstrap';
// import Form from 'react-bootstrap/Form';
import backgroundImage from './bg2.jpg';
import axios from 'axios';
import moment from 'moment';
// import { useHistory } from "react-router-dom";
import mixpanel from 'mixpanel-browser';

// / create an instance of the mixpanel client
mixpanel.init("24661fba09998e465e6f435c094ba5a2", {track_pageview: true});

var person
var past
var future
var future2
var future3
var future4
var future5
var future6
var future7
var current
var advice
var newgdates



const AgreementPage = ({ onNext, pastImportance, futureImportance, futureImportance2, futureImportance3, futureImportance4, futureImportance5, futureImportance6, futureImportance7}) => {
  const [dates, setDates] = useState([]);
  const [gdates, setGdates] = useState([]);
  const [newgdate, setNewGdates] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentImportance, setCurrentImportance] = useState(0);
  const [newgdates, setNewgdates] = useState('');
  const milliRange = 3000; // 1 second
  // newgdates = newgdate
  // console.log('newgdate: ',newgdates)
  // console.log('past importance:', pastImportance);
  // console.log('future importance:', futureImportance);
  // console.log('future importance2:', futureImportance2);
  // console.log('future importance3:', futureImportance3);
  // console.log('future importance4:', futureImportance4);
  // console.log('future importance5:', futureImportance5);


  useEffect(() => {
    // Generate an array of dates
    const currentDate = moment();
    const oneDay = moment(currentDate).add(1, 'day');
    const oneWeek = moment(currentDate).add(1, 'week');
    const oneMonth = moment(currentDate).add(1, 'month');
    const oneYear = moment(currentDate).add(1, 'year');
    const tenYears = moment(currentDate).add(10, 'years');
    const fiftyYears = moment(currentDate).add(50, 'years');
    const twohundredYears = moment(currentDate).add(200, 'years');

    const today = currentDate._d
    const oneDayLater = oneDay._d
    const oneWeekLater = oneWeek._d
    const oneMonthLater = oneMonth._d
    const oneYearLater = oneYear._d
    const tenYearsLater = tenYears._d
    const fiftyYearsLater = fiftyYears._d
    const twohundredYearsLater = twohundredYears._d

    // console.log(oneDayLater)
    // console.log(oneWeekLater)
    // console.log(oneMonthLater)
    // console.log(oneYearLater)
    // console.log("twohundredYearsLater: ", twohundredYearsLater)

    // const datesArray = [
    //   oneDayLater.format('DD/MMM/YYYY'),
    //   oneWeekLater.format('DD/MMM/YYYY'),
    //   oneMonthLater.format('DD/MMM/YYYY'),
    //   oneYearLater.format('DD/MMM/YYYY'),
    //   tenYearsLater.format('DD/MMM/YYYY'),
    // ];

    const datesArray = [
      today,
      oneDayLater,
      oneWeekLater,
      oneMonthLater,
      oneYearLater,
      tenYearsLater,
      fiftyYearsLater,
      twohundredYearsLater
    ];

    console.log(datesArray)

    setDates(datesArray);
  }, []);

useEffect(() => {
  const ranges = [0, pastImportance, futureImportance, futureImportance2, futureImportance3, futureImportance4, futureImportance5, futureImportance6, futureImportance7];
  let currentRangeIndex = 0;
  const animationDuration = 5000; // Set the duration of the animation in milliseconds (5 seconds)
  const framesPerSecond = 50;
  const totalFrames = (animationDuration / 1000) * framesPerSecond;
  const frameIncrement = (ranges[currentRangeIndex + 1] - ranges[currentRangeIndex]) / totalFrames;
  let frameCount = 0;
  // console.log('datescheck: ',dates)

  
  var intervalId = setInterval(() => {
    setCurrentImportance((prevImportance) => {
      var progress = frameCount / totalFrames;
      var easedProgress = 1 - Math.pow(1 - progress, 1); // Ease-out

      var newImportance =
        ranges[currentRangeIndex] < ranges[currentRangeIndex + 1]
          ? Math.min(prevImportance + frameIncrement + easedProgress * (ranges[currentRangeIndex + 1] - prevImportance), ranges[currentRangeIndex + 1])
          : Math.max(prevImportance + frameIncrement + easedProgress * (ranges[currentRangeIndex + 1] - prevImportance), ranges[currentRangeIndex + 1]);

      frameCount += 1;

      if (frameCount >= totalFrames || newImportance === ranges[currentRangeIndex + 1]) {
        frameCount = 0;
        currentRangeIndex += 1;
        // setGdates(dates[currentRangeIndex])
        // Reset to the initial range if all ranges have been covered
        if (currentRangeIndex === ranges.length - 1) {
          currentRangeIndex = 0;
          newImportance = pastImportance
        }
        // Update newgdates with the formatted value of the current date
        // if (dates[currentRangeIndex].length > 0) {
          const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            weekday: 'short',
          };
          const formattedDate = dates[currentRangeIndex].toLocaleDateString('en-GB', options);
          const formattedString = formattedDate.replace(' ', ' ').replace(' ', ' ');
          console.log('currentRangeIndex: ', currentRangeIndex)
          console.log('dateformated: ', formattedString)
          console.log('new_importance: ', newImportance)
          setNewgdates(formattedString);
        // }

      }

      return newImportance;
    });
  }, 1000 / framesPerSecond);

  // Cleanup the interval on component unmount
  return () => clearInterval(intervalId);
}, [dates]);

// useEffect(() => {
//   // Update label when currentImportance changes
//   setNewgdates(newgdate);
// }, []);


  return (
    <div className="page" >
      <h3 style={{ fontFamily: 'Young Serif', fontSize: '30px', fontWeight: 800, margin: 5, marginTop: 30, width: '80%' }}>
        {/* Whatever {person} does that gets to you. Remember it is this less important */}
        {/* This is your power, that nothing, not even {person} can touch you but yourself. */}
        {/* This is your superpower -- not even {person} can mess with you, only you have that kind of control! */}
         This is the future of the worry.
      </h3>
      <input
        style={{
          borderRadius: 30,
          marginTop: 30,
          height: 30,
          width: '80%',
          appearance: 'none',
          background: `linear-gradient(to right, black 0%, black ${currentImportance * 20}%, #CED4DA ${currentImportance * 20}%, #CED4DA 100%)`,
        }}
        variant="outline-dark"
        type="range"
        min="0"
        max="5"
        value={currentImportance}
        readOnly
      />
      <div style={{ textAlign: 'center' }}>
        {/* <label style={{ fontFamily: 'Lexend Deca', fontSize: '20px', fontWeight: 60, margin:10}}>{dates}</label> */}
      </div>
      <div style={{ textAlign: 'center' }}>
            <label style={{ fontFamily: 'Lexend Zetta', fontSize: '20px', fontWeight: 60, margin: 10 }}>{newgdates}</label>
      </div>
      <Button
        style={{
          fontFamily: 'Lexend Zetta',
          fontSize: '20px',
          fontWeight: 80,
          marginBottom: 70,
          marginTop: 'auto',
          borderRadius: 30,
          height: 60,
          width: '90%',
        }}
        variant="dark"
        onClick={() => onNext(currentImportance)}
      >
        F i n i s h
      </Button>
    </div>
  );
};


const RelationshipPage = ({ onNext }) => {
  // const relationships = [
  //   'Myself',
  //   'Friend',
  //   'Girlfriend',
  //   'Boyfriend',
  //   'Husband',
  //   'Wife',
  //   'Ex',
  //   'Father',
  //   'Mother',
  //   'Sibling',
  //   'Cousin',
  //   'Child',
  //   'Work Colleague',
  //   'Work Boss',
  //   'Classmate',
  //   'Neighbor',
  //   'Random Person',
  //   'Online Person',
  // ];

  const relationships = [
    'The Altar',
    'Work',
    'Home',
    'Friends',
    'School',
    "Dad's Side",
    "Mom's side",
    'TV',
    'Online',
    'Facebook',
    'X',
    'Instagram',
    'Tiktok',
    'Snapchat',
    'YouTube',
    'Discord',
    "WhatsApp",
    'Linkedin',
    'Hinge',
    'Tinder',
    'Telegram',
    'The Bus',
    'Church',
    'Temple',
    'Mosque',
    'The Bed',
    'The Womb',
    'The Streets',
    'The Past',
    'The Gutters',
    "The Cinema",
    "Family",
    'The Party',
    "The Club",
    'Dating App',
  ];

  const [personName, setPersonName] = useState('');
  const [selectedRelationship, setSelectedRelationship] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  console.log('personName: ', personName)
  person = personName

  const handleNext = () => {
    if (personName == '') {
      alert("You need to enter the name of a person who recently upset you.");
      return;
    }
    mixpanel.track('startButton', {
      name: personName,
      relationship: selectedRelationship,
      time: new Date().toLocaleString()
    })
    onNext({
      name: personName,
      relationship: selectedRelationship,
    });
  };


  return (
    <div className="page">
       <h3 style={{ fontFamily: 'Young Serif', fontSize: '30px', fontWeight: 800, margin: 5, marginTop: 30, marginBottom: 20, width: '80%'}}>Who dares to stress you?</h3>
      {/* <input
        type="text"
        placeholder="Enter person's name"
        value={personName}
        onChange={(e) => setPersonName(e.target.value)}
      />  */}
      {/* <Form.Select 
        style={{ height: 60,  margin: 20, borderRadius:30, width: '80%'}} variant="outline-dark"
        value={selectedRelationship}
        onChange={(e) => setSelectedRelationship(e.target.value)}
      >
        <option value="" disabled>
          What would you like to know?
        </option>
        {relationships.map((relationship) => (
          <option key={relationship} value={relationship}>
            {relationship}
          </option>
        ))}
      </Form.Select> */}
      
      <Form style={{fontFamily: 'Lexend Zetta', height: 45, margin: 5, marginTop: 0, borderRadius:30, width: '80%'}} variant="outline-dark">
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          {/* <Form.Label>Who's pressing your buttons?</Form.Label> */}
          <Form.Control type="text" value={personName}
        onChange={(e) => setPersonName(e.target.value)} style={{height: 60, textTransform: "capitalize"}} placeholder="example: Rishi Sunak" />
        </Form.Group>
      </Form>

      {/* <Form.Select 
        style={{ textTransform: "capitalize", fontFamily: 'Lexend Zetta', height: 60,  margin: 20, borderRadius:30, width: '80%'}} variant="outline-dark"
        value={selectedRelationship}
        onChange={(e) => setSelectedRelationship(e.target.value)}
      >
        <option value="" disabled>
          {personName} From?
        </option>
        {relationships.map((relationship) => (
          <option key={relationship} value={relationship}>
            {relationship}
          </option>
        ))}
      </Form.Select> */}


      {/* <Form
      style={{ width: '80%'}} 
      variant="outline-dark"
      >
      <Row>
        <Col>
          <Form.Check
            type="radio"
            label="Feel Upset?"
            id="radio-nothing"
            name="radioGroup"
            value="nothing"
            checked="checked"
            onChange={handleRadioChange}
          />
        </Col>
        <Col>
          <Form.Check
            disabled
            type="radio"
            label="Triggers"
            id="radio-people-pleasing"
            name="radioGroup"
            value="peoplePleasing"
            checked={selectedOption === "peoplePleasing"}
            onChange={handleRadioChange}
          />
        </Col>
        <Col>
          <Form.Check
            disabled
            type="radio"
            label="Relationships"
            id="radio-relationship"
            name="radioGroup"
            value="relationship"
            checked={selectedOption === "relationship"}
            onChange={handleRadioChange}
          />
        </Col>
        <Col>
          <Form.Check
            disabled
            type="radio"
            label="Career"
            id="radio-career"
            name="radioGroup"
            value="career"
            checked={selectedOption === "career"}
            onChange={handleRadioChange}
          />
        </Col>
      </Row>
    </Form> */}


      <Button style={{ fontFamily: 'Lexend Zetta', fontSize: '20px', fontWeight: 80, marginBottom: 70, marginTop: 'auto', borderRadius:30, height: 60, width: '90%'}} variant="dark" onClick={handleNext}>
        S t a r t
      </Button>
    </div>
  );
};

const ImportancePage = ({ question, personName, pastImportance, onNext }) => {
  const [importance, setImportance] = useState(0);

  useEffect(() => {
    // if (current === 1){
    //   past = importance
    // }else if(current === 2  && future === undefined){
    //   future = importance
    //   setImportance(past);
    // }else if((current === 3 || current === 4 || current === 5 || current === 6 || current === 7) && future === undefined){
    //   future = importance
    //   setImportance(future);
    // }else if((current === 2 || current === 3 || current === 4 || current === 5 || current === 6 || current === 7) && future !== undefined){
    //   future = importance
    //   // setImportance(past);
    // }
    if (current === 1){
      past = importance
      mixpanel.track('past', {
        name: personName,
        past: past,
        time: new Date().toLocaleString()
      })
    }else if(current === 2  && future === undefined){
      future = importance
      // setImportance(past);
    }else if(current === 2 && future !== undefined){
      future = importance
      // setImportance(past);
    }else if(current === 3 && future2 === undefined){
      future2 = importance
      // setImportance(future);
    }else if(current === 3 && future2 !== undefined){
      future2 = importance
      // setImportance(past);
    }else if(current === 4 && future3 === undefined){
      future3 = importance
      // setImportance(future2);
    }else if(current === 4 && future3 !== undefined){
      future3 = importance
      // setImportance(past);
    }else if(current === 5 && future4 === undefined){
      future4 = importance
      // setImportance(future3);
    }else if(current === 5 && future4 !== undefined){
      future4 = importance
      // setImportance(past);
    }else if(current === 6 && future5 === undefined){
      future5 = importance
      // setImportance(future4);
    }else if(current === 6 && future5 !== undefined){
      future5 = importance
      // setImportance(past);
    }else if(current === 7 && future6 === undefined){
      future6 = importance
      // setImportance(future5);
    }else if(current === 7 && future6 !== undefined){
      future6 = importance
      // setImportance(past);
    }else if(current === 8 && future7 === undefined){
      future7 = importance
      // setImportance(future6);
    }else if(current === 8 && future7 !== undefined){
      future7 = importance
      // setImportance(past);
      mixpanel.track('deathbed', {
        name: personName,
        importance: [past,future,future2,future3,future4,future5,future6,future7],
        time: new Date().toLocaleString()
      })
    }
      console.log('importance:', importance);
      console.log('past importance:', past);
      console.log('future importance:', future);
      console.log('future importance2:', future2);
      console.log('future importance3:', future3);
      console.log('future importance4:', future4);
      console.log('future importance5:', future5);
      console.log('future importance6:', future6);
      console.log('future importance6:', future7);
      console.log('current_page:', current);
    // Set the initial value only if importance is not already set
    // if (importance === 0) {
    //   setImportance(pastImportance);
    // }
  }, [importance]);

  return (
    <div className="page">
      <h3 style={{ fontFamily: 'Young Serif', fontSize: '30px', fontWeight: 800, margin: 5, marginTop: 30, width: '80%'}}>{question}</h3>
      <input style={{  borderRadius:30, 
      marginTop: 30,
      height: 30, 
      width: '80%',
      appearance: 'none',
      background: `linear-gradient(to right, black 0%, black ${importance * 20}%, #CED4DA ${importance * 20}%, #CED4DA 100%)`
      }} 
      variant="outline-dark"
        type="range"
        min="0"
        max="5"
        value={importance}
        onChange={(e) => setImportance(Number(e.target.value))}
      />
      <Button style={{ fontFamily: 'Lexend Deca', fontSize: '20px', fontWeight: 80, marginBottom: 70, marginTop: 'auto', borderRadius:30, height: 60, width: '90%'}} variant="dark" onClick={() => onNext(importance)}>
        N e x t
      </Button>
    </div>
  );
};

const ModalContent = ({ onBack }) => {
  // const history = useHistory();

  const quotes = [
    '"The most important relationship in your life is the relationship you have with yourself." - Diane von Furstenberg',
    "\"You can't give to others what you don't have for yourself. Make self-care a priority.\" - Oprah Winfrey",
    '"Your relationship with yourself sets the tone for every other relationship you have." - Robert Holden',
    '"Self-care is never a selfish act; it is simply good stewardship of the only gift I have, the gift I was put on this earth to offer to others." - Parker Palmer',
    '"Love yourself first, and everything else falls into line." - Lucille Ball',
    '"You are your best thing." - Toni Morrison',
    "\"Your time and energy are precious. You get to choose how you use it. Don't waste your essence on anything that doesn't serve you.\" - Maria Forleo",
    '"When you adopt the viewpoint that there is nothing that exists that is not part of you, that there is no one who exists who is not part of you, that any judgment you make is self-judgment, you will wisely extend to yourself an unconditional love that will be the light of the world." - Harry Palmer',
    '"The better you feel about yourself, the less you feel the need to show off." - Robert Hand',
    '"Self-love is the source of all our other loves." - Pierre Corneille'
]

  const getRandomQuote = () => {
    const randomIndex = Math.floor(Math.random() * quotes.length);
    return quotes[randomIndex];
  };

  const shareOnWhatsApp = () => {
    mixpanel.track('sharedWhatsapp', {
      quote: randomQuote,
      time: new Date().toLocaleString()
    })
    const shareUrl = "https://worryKiller.com"; // Replace with your actual website URL
    const message = "Marriage life is wonderful but can come with some worrying. Get perspective on your worries with "; // Customize your message

    const whatsappLink = `whatsapp://send?text=${encodeURIComponent(`${message} ${shareUrl}`)}`;
    window.location.href = whatsappLink;
  };

  const [randomQuote, setRandomQuote] = useState("");
  advice = randomQuote
  useEffect(() => {
    // if (currentPage === 5) {
      const quote = getRandomQuote();
      setRandomQuote(quote);
    // }
    console.log(randomQuote)
  }, []);

  return (
    <div className="page">
    <div>
      <h3 style={{ fontFamily: 'Young Serif', fontSize: '30px', fontWeight: 800, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', margin: 'auto', marginTop: 30, width: '80%' }}>{advice}</h3>
    </div>
    <Button
      style={{ fontFamily: 'Lexend Deca', fontSize: '20px', fontWeight: 80, marginTop: 'auto', borderRadius: 30, height: 60, width: '90%', margin: 30 }}
      variant="dark"
      onClick={shareOnWhatsApp}
    >
      W h a t s a p p
    </Button>
    {/* <Button
        style={{ fontFamily: 'Lexend Deca', fontSize: '20px', fontWeight: 80, marginBottom: 70,  borderRadius:30, height: 60, width: '90%', marginTop:0}} variant="dark"
        onClick={onBack}
      >
        W o r k e d ?
      </Button> */}
    </div>
  );
};

const App = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [relationship, setRelationship] = useState('');
  const [personName, setPersonName] = useState('');
  const [pastImportance, setPastImportance] = useState(0);
  const [futureImportance, setFutureImportance] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [agreements, setAgreements] = useState({
    lowerExpectations: false,
    lowerExpectationsB: false,
    lowerExpectationsC: false,
    // Add other agreements as needed
  });

  // mixpanel.track('landed', {
  //   time: new Date().toLocaleString()
  // })
  

  // const [advice, setAdvice] = useState('');
  // const [displayedAdvice, setDisplayedAdvice] = useState('Use This Tool Wisely');
  // advice = displayedAdvice

  // const fetchAdvice = async () => {
  //   try {
  //     const response = await axios.post(
  //       'https://api.openai.com/v1/chat/completions',
  //       {
  //         "model": "gpt-4",
  //         "messages": [
  //           {
  //             "role": "user",
  //             "content": "give a quote about making yourself a priority in a relationship where you feel pressure to be accepted. give the name of the person that said the quote"
  //             // "content": "Write a 1 sentence advicing with no commas someone to make themselves a priority in a relationship where they feel pressure to be accepted."
  //           }
  //         ],
  //         "temperature": 0.8,
  //         "max_tokens": 64,
  //         "top_p": 1
  //       },
  //       {
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'Authorization': 'Bearer key',
  //         },
  //       }
  //     );
  //       // console.log(response.data.choices[0].message.content)
  //       const adviceText = response.data.choices[0].message.content
  //       // setAdvice(adviceText);
  //       setDisplayedAdvice(adviceText); // Set advice to be displayed
  //   } catch (error) {
  //     console.error('Error fetching advice:', error.message);
  //     // setAdvice('Failed to fetch advice.');
  //   }
  // };

  // useEffect(() => {
  //   if (currentPage === 5) {
  //     fetchAdvice();
  //   }
  // }, [currentPage]);



  const handleNext = (value) => {
    if (currentPage === 1) {
      current = currentPage
      setPersonName(value.name);
      setRelationship(value.relationship);
    } else if (currentPage === 2) {
      current = currentPage
      setPastImportance(value);
    } else if (currentPage === 3) {
      current = currentPage
      setFutureImportance(value);
      // handleModalOpen(); // Open the modal after setting the futureImportance
    }else if (currentPage === 4) {
      current = currentPage
      setFutureImportance(value);
      // handleModalOpen(); // Open the modal after setting the futureImportance
    }else if (currentPage === 5) {
      current = currentPage
      setFutureImportance(value);
      // handleModalOpen(); // Open the modal after setting the futureImportance
    }else if (currentPage === 6) {
      current = currentPage
      setFutureImportance(value);
      // handleModalOpen(); // Open the modal after setting the futureImportance
    }else if (currentPage === 7) {
      current = currentPage
      setFutureImportance(value);
      // handleModalOpen(); // Open the modal after setting the futureImportance
    }else if (currentPage === 8) {
      current = currentPage
      setFutureImportance(value);
      // handleModalOpen(); // Open the modal after setting the futureImportance
    }else if (currentPage === 9) {
      current = currentPage
      setFutureImportance(value);
      // handleModalOpen(); // Open the modal after setting the futureImportance
    }else if (currentPage === 10) {
      current = currentPage
      setAgreements(value);
      handleModalOpen(); // Open the modal after setting the futureImportance
    }else if (currentPage === 11) {
      current = currentPage
      // setAgreements(value);
      handleModalClose(); // Open the modal after setting the futureImportance
    }
    setCurrentPage(currentPage + 1);
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };
  
  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleBackToPage1 = () => {
    setCurrentPage(1);
    setIsModalOpen(false);
  };

  return (
    <div className="App" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      {/* Navbar */}
      {/* <Navbar
        currentPage={currentPage}
        personName={personName}
        appName="MyButtons"
      /> */}
      {currentPage === 1 && <RelationshipPage onNext={handleNext} />}
      {currentPage === 2 && (
        <ImportancePage
          question={`How bothered are you about what ${person} did? The highest is - You feel so upset you can't think of anything else.`}
          personName={personName}
          onNext={handleNext}
        />      
        )}
      {currentPage === 3 && (
        <ImportancePage
          question={`How bothered will you feel about what ${person} did after 1 day?`}
          personName={personName}
          onNext={handleNext}
        />
      )}
      {currentPage === 4 && (
        <ImportancePage
          question={`How bothered will you feel about what ${person} did after 1 week?`}
          personName={personName}
          onNext={handleNext}
        />
      )}
      {currentPage === 5 && (
        <ImportancePage
          question={`How bothered will you feel about what ${person} did after 1 month?`}
          personName={personName}
          onNext={handleNext}
        />
      )}{currentPage === 6 && (
        <ImportancePage
          question={`How bothered will you feel about what ${person} did after 1 year?`}
          personName={personName}
          onNext={handleNext}
        />
      )}{currentPage === 7 && (
        <ImportancePage
          question={`How bothered will you feel about what ${person} did after 10 years?`}
          personName={personName}
          onNext={handleNext}
        />
      )}{currentPage === 8 && (
        <ImportancePage
          question={`How bothered will you feel about what ${person} did after 50 years?`}
          personName={personName}
          onNext={handleNext}
        />
      )}{currentPage === 9 && (
        <ImportancePage
          question={`How bothered will you feel about what ${person} did on your Dying Bed?`}
          personName={personName}
          onNext={handleNext}
        />
      )}
      {/* {currentPage === 4 && <AgreementPage onNext={handleNext} />} */}
      {currentPage === 10 && <AgreementPage onNext={handleNext} pastImportance={past} futureImportance={future} futureImportance2={future2} futureImportance3={future3} futureImportance4={future4} futureImportance5={future5} futureImportance6={future6} futureImportance7={future7}/>}
      {/* {currentPage === 5 && <AgreementPage onNext={handleNext} pastImportance={past} futureImportance={future}/>} */}
      {currentPage === 11 && (
        <div>
          {/* <h7>{displayedAdvice}</h7> */}
          <ModalContent onBack={handleBackToPage1} displayedAdvice={advice} />
        </div>
      )}
    </div>
  );
};

export default App;
